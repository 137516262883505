import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { load } from "@cashfreepayments/cashfree-js";
import styles from "./Wishlist.css";

const Payment = ({
  cartItems,
  userDetails,
  orderAmount,
  redeemCoins,
  buttonText = "Buy Now",
  className = "buy--now",
  style = {},
}) => {
  const [cashfree, setCashfree] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const initializeSDK = async () => {
      try {
        const cashfreeInstance = await load({ mode: "production" });
        setCashfree(cashfreeInstance);
      } catch (error) {
        console.error("Failed to initialize Cashfree SDK:", error);
        Swal.fire({
          icon: "error",
          title: "SDK Error",
          text: "Failed to initialize payment SDK. Please reload the page.",
        });
      }
    };
    initializeSDK();
  }, []);

  const doPayment = async (id, orderId) => {
    if (!cashfree) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Payment SDK not initialized. Please try again.",
      });
      return;
    }

    try {
      cashfree.checkout({
        paymentSessionId: id,
        returnUrl: `https://auth.ssccglpinnacle.com/api/ebook_payment_status_cashfree/${orderId}`,
        redirectTarget: "_blank",
        onSuccess: (paymentResponse) => {
          console.log("Payment successful:", paymentResponse);

          Swal.fire({
            icon: "success",
            title: "Payment Successful",
            text: "Your payment was successful. Coins will be redeemed shortly.",
          });
        },
        onFailure: (paymentResponse) => {
          console.error("Payment failed:", paymentResponse);
          Swal.fire({
            icon: "error",
            title: "Payment Failed",
            text: "Your payment could not be processed. Please try again.",
          });
        },
      });

      // Schedule redeem coins API call after 1 minute
      // setTimeout(() => {
      //   redeemCoinsAPI(orderId, userDetails._id, redeemCoins);
      // }, 60000);
    } catch (error) {
      console.error("Error during payment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred during payment. Please try again.",
      });
    }
  };

  const payMe = async () => {
    if (!userDetails || !userDetails._id) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Please log in to make a purchase.",
      });
      return;
    }

    if (!cartItems || cartItems.length === 0 || !orderAmount) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid cart or order details. Please try again.",
      });
      return;
    }

    try {
      setIsLoading(true);
      const response = await fetch(
        "https://auth.ssccglpinnacle.com/api/ebook_payment_cashfree",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: userDetails._id,
            cartItems,
            orderAmount,
            redeemCoins
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to initiate payment");
      }

      const responseData = await response.json();
      if (responseData.error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: responseData.error,
        });
      } else {
        doPayment(responseData.cftoken, responseData.orderId);
      }
    } catch (error) {
      console.error("Error initiating payment:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred. Please try again.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  // const redeemCoinsAPI = async (orderId, userId, coinsToRedeem) => {
  //   console.log("RedeemCoinsAPI called with:", {
  //     orderId,
  //     userId,
  //     coinsToRedeem,
  //   });
  //   try {
  //     const response = await fetch(
  //       `https://auth.ssccglpinnacle.com/api/redeem-coins/${orderId}`,
  //       {
  //         method: "PUT",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userId,
  //           coinsToRedeem,
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to redeem coins");
  //     }

  //     const data = await response.json();
  //     console.log("Coins redeemed successfully:", data);
  //     Swal.fire({
  //       icon: "success",
  //       title: "Coins Redeemed",
  //       text: `Coins redeemed successfully. Remaining Coins: ${data.remainingCoins}`,
  //     });
  //   } catch (error) {
  //     console.error("Error redeeming coins:", error);
  //     Swal.fire({
  //       icon: "error",
  //       title: "Error",
  //       text: "Failed to redeem coins. Please try again.",
  //     });
  //   }
  // };

  return (
    <button
      onClick={payMe}
      className={`${styles.buyNowButton} ${className}`}
      style={style}
      disabled={isLoading}
    >
      {isLoading ? "Processing..." : buttonText}
    </button>
  );
};

export default Payment;












































// import React, { useEffect, useState } from "react";
// import Swal from "sweetalert2";
// import { load } from "@cashfreepayments/cashfree-js";
// import styles from "./Wishlist.css";

// const Payment = ({
//   cartItems, // Expecting cartItems array
//   userDetails,
//   orderAmount,
//   buttonText = "Buy This Ebook",
//   className = "buy--now",
//   style = {},
// }) => {
//   // const [cashfree, setCashfree] = useState(null);

//   // useEffect(() => {
//   //   const initializeSDK = async () => {
//   //     const cashfreeInstance = await load({
//   //       mode: "production", // Set to production mode
//   //     });
//   //     setCashfree(cashfreeInstance);
//   //   };
//   //   initializeSDK();
//   // }, []); // Empty dependency array ensures this runs only once

//   // const doPayment = async (id, orderId) => {
//   //   if (cashfree) {
//   //     cashfree.checkout({
//   //       paymentSessionId: id,
//   //       returnUrl: `http://localhost:5000/api/ebook_payment_status_cashfree/${orderId}`,
//   //       redirectTarget: "_self",
//   //       onSuccess: (paymentResponse) => {
//   //         console.log("Payment successful", paymentResponse);
//   //       },
//   //       onFailure: (paymentResponse) => {
//   //         console.error("PAYMENT FAILED", paymentResponse);
//   //       },
//   //     });
//   //   } else {
//   //     console.error("Cashfree SDK not initialized");
//   //   }
//   // };

//   const [cashfree, setCashfree] = useState(null);

//   useEffect(() => {
//     const initializeSDK = async () => {
//       const cashfreeInstance = await load({
//         mode: "production",
//         // mode: "sandbox",
//       });
//       setCashfree(cashfreeInstance);
//     };
//     initializeSDK();
//   }, []); // Empty dependency array ensures this runs only once

//   const doPayment = async (id, orderId) => {
//     if (cashfree) {
//       cashfree.checkout({
//         paymentSessionId: id,
//         returnUrl: `https://auth.ssccglpinnacle.com/api/ebook_payment_status_cashfree/${orderId}`,
//         redirectTarget: "_blank",
//         onSuccess: (paymentResponse) => {
//           console.log("Payment successful", paymentResponse);
//         },
//         onFailure: (paymentResponse) => {
//           console.error("PAYMENT FAILED", paymentResponse);
//         },
//       });
//     } else {
//       console.error("Cashfree SDK not initialized");
//     }
//   };

//   console.log(userDetails);

//   const payMe = async () => {
//     try {
//       if (!userDetails || !userDetails._id) {
//         Swal.fire({
//           icon: "error",
//           title: "Error",
//           text: "Please log in to make a purchase.",
//         });
//         return;
//       }

//       const data = {
//         userId: userDetails._id,
//         cartItems, // Sending the cartItems array
//         orderAmount: orderAmount,
//       };

//       console.log("DATA", data);
//       const url = "https://auth.ssccglpinnacle.com/api/ebook_payment_cashfree";
//       const response = await fetch(url, {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(data),
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         console.log("RESPONSEDATA", responseData);
//         if (responseData.error) {
//           Swal.fire({
//             icon: "error",
//             title: "Error",
//             text: responseData.error,
//           });
//         } else {
//           doPayment(responseData.cftoken, responseData.orderId);
//         }
//       } else {
//         Swal.fire({
//           icon: "error",
//           title: "Error",
//           text: "Failed to initiate payment. Please try again.",
//         });
//       }
//     } catch (err) {
//       console.error("Error fetching data:", err);
//       Swal.fire({
//         icon: "error",
//         title: "Error",
//         text: "An error occurred. Please try again.",
//       });
//     }
//   };

//   return (
//     <button
//       onClick={payMe}
//       className={`${styles.buyNowButton} ${className}`}
//       style={style}
//     >
//       {buttonText}
//     </button>
//   );
// };

// export default Payment;
