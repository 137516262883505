import React from "react";
import styles from "./Footer.module.css";
import youtube from "./footerimg/youtube.svg";
import telegram from "./footerimg/telegram.svg";
import whatsapp from "./footerimg/whatsapp.svg";
import play from "./footerimg/play.svg";
import gmail from "./footerimg/gmail.svg";
import facebook from "./footerimg/facebook.svg";
import captureLink from "../component/HomePage01/utils/captureLinks"; // Import captureLink utility

function Footer() {
  const handleLinkClick = (url, text) => {
    const linkData = {
      page: window.location.pathname,
      url,
      text,
    };

    // Log the link using captureLink
    captureLink(linkData);
  };

  return (
    <div className={styles.siteFooter}>
      <div className={styles.footerColumn}>
        <h5>Company</h5>
        <ul className={styles.footerLinks}>
          <li>
            <a
              href="/about-us"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/about-us", "About Us")}
            >
              About Us
            </a>
          </li>
          <li>
            <a
              href="/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick("/privacy-policy", "Privacy Policy")
              }
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="/terms-and-condition"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick("/terms-and-condition", "Terms & Conditions")
              }
            >
              Terms & Conditions
            </a>
          </li>
          <li>
            <a
              href="/refund-policy"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/refund-policy", "Refund Policy")}
            >
              Refund Policy
            </a>
          </li>
          <li>
            <a
              href="/contact-us"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/contact-us", "Contact Us")}
            >
              Contact Us
            </a>
          </li>
          <li>
            <a
              href="/sitemap"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/sitemap", "Sitemap")}
            >
              Sitemap
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.footerColumn}>
        <h5>Explore</h5>
        <ul className={styles.footerLinks}>
          <li>
            <a
              href="https://testportal.ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick(
                  "https://testportal.ssccglpinnacle.com",
                  "Test Portal"
                )
              }
            >
              Test Portal
            </a>
          </li>
          <li>
            <a
              href="/typing"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/typing", "Typing")}
            >
              Typing
            </a>
          </li>
          <li>
            <a
              href="/books"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/books", "Books")}
            >
              Books
            </a>
          </li>
          <li>
            <a
              href="https://videos.ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick("https://videos.ssccglpinnacle.com", "Videos")
              }
            >
              Videos
            </a>
          </li>
          <li>
            <a
              href="/ebooks"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/ebooks", "E-Books")}
            >
              E-Books
            </a>
          </li>
          <li>
            <a
              href="/blogs"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => handleLinkClick("/blogs", "Blogs")}
            >
              Blogs
            </a>
          </li>
        </ul>
      </div>

      <div className={styles.footerColumn}>
        <h5>Connect</h5>
        <ul className={styles.footerLinks}>
          <li className={styles.socialIcons}>
            <img src={youtube} alt="YouTube" />
            <a
              href="https://www.youtube.com/@pinnaclepublications"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick(
                  "https://www.youtube.com/@pinnaclepublications",
                  "YouTube"
                )
              }
            >
              YouTube
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={telegram} alt="Telegram" />
            <a
              href="https://t.me/ssccglpinnacleonline"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick("https://t.me/ssccglpinnacleonline", "Telegram")
              }
            >
              Telegram
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={whatsapp} alt="WhatsApp" />
            <a
              href="https://whatsapp.com/channel/0029VaFZ5QTC6ZvcTFgbMe2J"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick(
                  "https://whatsapp.com/channel/0029VaFZ5QTC6ZvcTFgbMe2J",
                  "WhatsApp"
                )
              }
            >
              WhatsApp
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={facebook} alt="Facebook" />
            <a
              href="https://www.facebook.com/Pinnacle-SSC-Coaching-676467975776244/timeline"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick(
                  "https://www.facebook.com/Pinnacle-SSC-Coaching-676467975776244/timeline",
                  "Facebook"
                )
              }
            >
              Facebook
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={play} alt="Google Play" />
            <a
              href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick(
                  "https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal",
                  "Download App"
                )
              }
            >
              Download App
            </a>
          </li>
          <li className={styles.socialIcons}>
            <img src={gmail} alt="Email" />
            <a
              href="mailto:support@ssccglpinnacle.com"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() =>
                handleLinkClick("mailto:support@ssccglpinnacle.com", "Email")
              }
            >
              support@ssccglpinnacle.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Footer;
