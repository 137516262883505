import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { FaArrowLeft } from "react-icons/fa";
import styles from "./ViewVideoPdf.module.css";
// import { useTheme } from "../Context/ThemeContext";

const ViewVideoPdf = () => {
  const { pdfId } = useParams();
  const navigate = useNavigate();
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfTitle, setPdfTitle] = useState("PDF Viewer");
  //   const { isDarkMode, toggleTheme } = useTheme();
  const toolbarPluginInstance = toolbarPlugin();
  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/api/pdfs/${pdfId}`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch PDF details.");
        }
        const data = await response.json();
        setPdfTitle(data.originalname || "PDF Viewer");
        setPdfUrl(data.cloudFrontUrl);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchPdf();
  }, [pdfId]);
  const handleBack = () => navigate("/");
  return (
    <div style={{ height: "100vh" }} className={styles.pdfContainer}>
      {/* Header */}
      <header
        style={{
          display: "flex",
          alignItems: "center",
          padding: "10px",

          //   backgroundColor: isDarkMode ? "#222" : "#4189B6",
          //   color: isDarkMode ? "#fff" : "#fff",
        }}
      >
        <FaArrowLeft
          onClick={handleBack}
          style={{ cursor: "pointer", marginRight: "10px" }}
        />
        <h1 style={{ fontSize: "16px", margin: 0 }}>
          {pdfTitle || "PDF Viewer"}
        </h1>
        {/* <button
          style={{
            marginLeft: "auto",
            padding: "5px 10px",
            // backgroundColor: isDarkMode ? "#fff" : "#222",
            // color: isDarkMode ? "#222" : "#fff",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          onClick={toggleTheme}
        >
          {isDarkMode ? "Light Mode" : "Dark Mode"}
        </button> */}
      </header>
      {/* Content */}
      <div style={{ padding: "20px", height: "calc(100vh - 60px)" }}>
        {loading && (
          <div className={styles["loading-container"]}>
            <img
              src="./Bubble-Preloader.gif"
              alt="Loading"
              className={styles["loading-image"]}
            />
          </div>
        )}
        {error && <p style={{ color: "red" }}>{error}</p>}
        {pdfUrl && (
          <div style={{ height: "100%" }}>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
              <Viewer
                fileUrl={pdfUrl}
                plugins={[
                  toolbarPluginInstance,
                  {
                    toolbarItems: (toolbarItems) =>
                      toolbarItems.filter(
                        (item) =>
                          !["download", "print", "open", "search"].includes(
                            item.key
                          )
                      ),
                  },
                ]}
              />
            </Worker>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewVideoPdf;
