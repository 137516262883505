import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import logo from "../../component/HeaderFiles/images/pinnacleWhiteLogo.png";
import google from "../../component/HeaderFiles/images/google.svg";
import banner_ssc_cgl_tier2 from "../../component/HeaderFiles/images/banner_ssc_cgl_tier2.png";
import banner_railway_tcs from "../../component/HeaderFiles/images/banner_railway_tcs.png";
import banner_typing from "../../component/HeaderFiles/images/banner_typing.png";
import banner_tcs_test_pass from "../../component/HeaderFiles/images/banner_tcs_test_pass.png";
import { GiBlackBook } from "react-icons/gi";
import { SlBookOpen } from "react-icons/sl";
import { FaRegClipboard } from "react-icons/fa";
import { MdOutlineOndemandVideo } from "react-icons/md";
import { FiAlertTriangle } from "react-icons/fi";
import { BiBarChart } from "react-icons/bi";
import { AiFillEyeInvisible } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { IoIosChatbubbles } from "react-icons/io";
import Chatbot from "../../chatBot/Chatbot";

import { useAuth } from "../../Context/AuthContext";
import { Link, useNavigate } from "react-router-dom";

import "./Row2.css";
import classes from "./Row1.module.css";

function Row1() {
  const { isLoggedIn, logout } = useAuth(); // Use useAuth hook to get isLoggedIn and logout
  const [showChatbot, setShowChatbot] = useState(false);
  // const [imgData, setImgData] = useState();

  // const handleBuyProduct = () => {
  //   navigate(`/individualexamtestpassbuy/${121}`);
  // };

  const handleChatbotOpen = () => setShowChatbot(true);
  const handleChatbotClose = () => setShowChatbot(false);

  // console.log("isLoggedIn", isLoggedIn);

  return (
    <Container fluid className={classes.Container1}>
      <div className={classes.home_main_div}>
        <div>
          <a
            href="https://testportal.ssccglpinnacle.com/buy/ssc-tcs-test-pass"
            target="_blank"
            rel="noopener noreferrer"
          >          
            <img
              className={classes.banner_img}
              src={banner_tcs_test_pass}
              alt="Add a heading(1)"
            />
          </a>
        </div>
        <div>
          <a
            href="https://testportal.ssccglpinnacle.com/buy/ssc-cgl-tier-2"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.banner_img}
              src={banner_ssc_cgl_tier2}
              alt="Add a heading(2)"
            />
          </a>
        </div>
        <div
          className={`${classes.whiteContainer} ${classes.mobile_display_none}`}
        >
          <a
            href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal"
            target="_blank"
          >
            <img
              className={classes.google_play_img}
              src={google}
              alt="Google Play Store"
            />
          </a>
        </div>
      </div>

      <div className={classes.home_main_div}>
        <div>
          <a
            href="https://ssccglpinnacle.com/typing"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.banner_img}
              src={banner_typing}
              alt="Add a heading(4)"
            />
          </a>
        </div>
        <div>
          <a
            href="https://testportal.ssccglpinnacle.com/buy/railway-tcs-test-pass"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={classes.banner_img}
              src={banner_railway_tcs}
              alt="Add a heading(3)"
            />
          </a>
        </div>

        <div className={classes.whiteContainer}>
          <h4>Get started with PINNACLE</h4>
          <h5>Ensure your success with us</h5>

          {!isLoggedIn ? (
            <div className={classes.button_for_login_register}>
              <button className={classes.forloggedin}>
                <a
                  href="https://testportal.ssccglpinnacle.com/login"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Login
                </a>
              </button>
              <button className={classes.register_homepage}>
                <a
                  href="https://testportal.ssccglpinnacle.com/login"
                  style={{ color: "inherit", textDecoration: "none" }}
                >
                  Register
                </a>
              </button>
            </div>
          ) : (
            <div className={classes.logged_message}>
              <p className={classes.already}>You are already logged in.</p>
              <button onClick={logout} className={classes.logout_homepage}>
                Logout
              </button>
            </div>
          )}

          {/* Chatbot Icon */}
          <div className={classes.chatbot_icon}>
            <IoIosChatbubbles
              onClick={handleChatbotOpen}
              size={50}
              style={{
                cursor: "pointer",
                position: "fixed",
                bottom: "20px",
                right: "20px",
                color: "#007bff",
              }}
            />
          </div>

          {/* Chatbot Modal */}
          <Modal show={showChatbot} onHide={handleChatbotClose} centered>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Chatbot />
            </Modal.Body>
            {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleChatbotClose}>
            Close
          </Button>
        </Modal.Footer> */}
          </Modal>

          {/* Optional: Remove commented code if not needed */}
          {/* {!cookieAuthentication && <LoginEmailPass />} */}
          {/* <div className={classes.new_expense__control}>
                <input
                  type="text"
                  placeholder="Enter your mobile number"
                  id="mobno"
                />
              </div>
              <div className={classes.continue}>
                <button
                  type="button"
                  className={classes.pinnacle_registe_form_button}
                >
                  Continue
                </button>
              </div>
              <div className={classes.continue1}>
                <a href="/">
                  <h4>Login with email</h4>
                </a>
              </div> */}
        </div>
      </div>

      {/* <div className={classes.for_wrap}>
        <Row className={classes.Row1}>
          <Col className={classes.Col1} xs={11} md={7}>
            <img src={logo} alt="Pinnacle Logo" />
            <h1>&nbsp; EXAM PREPARATION APP</h1>
          </Col>

          <Col className={classes.colforapp} xs={7} md={5}>
            <a href="https://play.google.com/store/apps/details?id=com.ssccgl.pinnacle.testportal">
              <img src={google} alt="Google Play Store" />
            </a>
          </Col>
        </Row>
      </div> */}
      <div className={classes.for_wrap2}>
        <Row className={classes.Row2}>
          {/* <Col className={classes.secondcol} xs={11} md={7}>
            <h6 className={classes.para1}>
              The most comprehensive coverage, largest product range SSC |
              Railway | Teaching | Police
            </h6>
            <div className={classes.bookshow}>
              <div className={classes.icons}>
                <GiBlackBook
                  className={classes.bookicons}
                  color="white"
                  fontSize="4em"
                />
                <p className={classes.para2}> 200+ books</p>
              </div>
              <div className={classes.icons}>
                <SlBookOpen
                  className={classes.ebookicons}
                  color="white"
                  fontSize="3.8em"
                />
                <p className={classes.para2}> 200+ ebooks</p>
              </div>
              <div className={classes.icons}>
                <FaRegClipboard
                  className={classes.board}
                  color="white"
                  fontSize="4em"
                />
                <p className={classes.para2}> 1000+ Tests</p>
              </div>
              <div className={classes.icons}>
                <MdOutlineOndemandVideo
                  className={classes.videoicons}
                  color="white"
                  fontSize="4em"
                />
                <p className={classes.para2}> 300+ Videos</p>
              </div>
            </div>
            <h5 className={classes.para3}>
              One stop solution for all TCS asked papers
            </h5>
            <div className={classes.para5}>
              <div className={classes.icontext}>
                <FiAlertTriangle
                  className={classes.iconfortext}
                  color="white"
                  fontSize="1.8em"
                />
              </div>
              &nbsp; <p> Know where do you stand today</p>
            </div>
            <div className={classes.para5}>
              <div className={classes.icontext}>
                <BiBarChart
                  className={classes.iconfortext}
                  color="white"
                  fontSize="1.8em"
                />
              </div>
              &nbsp; <p> What actions you can take to crack the exam</p>
            </div>
            <div className={classes.para5}>
              <div className={classes.icontext}>
                <AiFillEyeInvisible
                  className={classes.iconfortext}
                  color="white"
                  fontSize="1.8em"
                />
              </div>
              &nbsp; <p> Compete in your Category</p>
            </div>
          </Col> */}
          {/* <Col className={classes.secondcol1} xs={15} md={7}>
            <Col className={classes.whiteContainer}>
              <h4>Get started with PINNACLE</h4>
              <h5>Ensure your success with us</h5>

              {!isLoggedIn ? (
                <div className={classes.button_for_login_register}>
                  <button className={classes.forloggedin}>
                    <a
                      href="https://testportal.ssccglpinnacle.com/login"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      Login
                    </a>
                  </button>
                  <button className={classes.register_homepage}>
                    <a
                      href="https://testportal.ssccglpinnacle.com/login"
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      Register
                    </a>
                  </button>
                </div>
              ) : (
                <div className={classes.logged_message}>
                  <p className={classes.already}>You are already logged in.</p>
                  <button onClick={logout} className={classes.logout_homepage}>
                    Logout
                  </button>
                </div>
              )}

              Optional: Remove commented code if not needed
              {!cookieAuthentication && <LoginEmailPass />}
              <div className={classes.new_expense__control}>
                <input
                  type="text"
                  placeholder="Enter your mobile number"
                  id="mobno"
                />
              </div>
              <div className={classes.continue}>
                <button
                  type="button"
                  className={classes.pinnacle_registe_form_button}
                >
                  Continue
                </button>
              </div>
              <div className={classes.continue1}>
                <a href="/">
                  <h4>Login with email</h4>
                </a>
              </div>
            </Col>
          </Col> */}
        </Row>
      </div>
    </Container>
  );
}

export default Row1;
