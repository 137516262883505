import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import styles from "./CDetails.module.css";
import Details from "./Details";
import Requirements from "./Requirements";
import ContentDetails from "./ContentDetails";
import FacultyComponent from "./MoreComponents/FacultyComponent";
import ChapterAccordion from "./ChapterAccordion";
import Cart from "./Cart";
import Description from "./Description";

export default function CourseDetails() {
  const { category, subject, seoPermalink } = useParams(); // Extracts the full path after "/videos/"

  // const seoPermalink =
  //   "general-studies-portion-video-course-ssc-cgl-2024-39-sets-solved-paper-book";
  // console.log("seoPermalink", seoPermalink);
  // Local state to manage course details
  const [courseId, setCourseId] = useState("");
  const [courseTitle, setCourseTitle] = useState("");
  const [courseDetails, setCourseDetails] = useState("");
  const [teacherName, setTeacherName] = useState("");
  const [rating, setRating] = useState(0);
  const [totalRatingStudent, setTotalRatingStudents] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getCourseDetails = async () => {
      try {
        let result = await fetch(
          `https://auth.ssccglpinnacle.com/get-seo/${category}/${subject}/${seoPermalink}`
        );
        if (!result.ok) {
          throw new Error("Failed to fetch course details");
        }
        result = await result.json();
        // console.log("API Response:", result); // Debug the API response

        // Set courseId and other details
        setCourseId(result._id || result.id || "");
        // console.log("Course ID:", courseId);
        setCourseTitle(result.courseTitle || "Course Title Not Available");
        setCourseDetails(result.shortDescription || "No description available");
        setTeacherName(result.instructorName || "Instructor not available");

        const safeRating = Math.max(0, Math.min(result.averageRating || 0, 5));
        setRating(safeRating);

        setTotalRatingStudents(result.totalRatings || 0);
      } catch (error) {
        console.error("Error fetching course details:", error);
        setError("Failed to load course details.");
      }
    };

    getCourseDetails();
  }, [seoPermalink]);

  if (error) {
    return <div className={styles["error-message"]}>{error}</div>;
  }

  return (
    <>
      <div className={styles["full-Course-Details"]}>
        <Details
          courseTitle={courseTitle}
          courseDetails={courseDetails}
          teacherName={teacherName}
          rating={rating}
          totalRatingStudent={totalRatingStudent}
        />
        <div className={styles["subscription-cart"]}>
          <Cart courseId={courseId} />
        </div>
        <div className={styles["another-Details-div"]}>
          <div className={styles["course-content-Details"]}>
            <ContentDetails />
          </div>
          <div className={styles["Course-content-Accordion-div"]}>
            <div className={styles["Accordion-heading-div"]}>
              Course content
            </div>
            <ChapterAccordion courseId={courseId} />
          </div>

          <div className={styles["requirements-div"]}>
            <Requirements />
            <div className={styles["description-div"]}>
              <Description courseId={courseId} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
