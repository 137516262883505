import React, { useEffect, useState } from "react";
import classes from "./Navbar2.module.css";
import { FaHome, FaUser, FaKeyboard } from "react-icons/fa";
import { BsFillMenuButtonWideFill } from "react-icons/bs";
import { HiOutlineBookOpen } from "react-icons/hi";
import { AiOutlineLineChart } from "react-icons/ai";
import { MdOndemandVideo } from "react-icons/md";
import { ImBlogger } from "react-icons/im";
import { AiOutlineUser } from "react-icons/ai";
import { FaCoins } from "react-icons/fa6";
import { CSSTransition } from "react-transition-group";
import { Link } from "react-router-dom";
import { useAuth } from "../../Context/AuthContext";
import { useCookies } from "react-cookie";

function Navbar1(props) {
  const { isLoggedIn, logout } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);

  const [cookies] = useCookies(["token"]);
  const [hasTypingAccess, setHasTypingAccess] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  useEffect(() => {
    const checkAccessTypingProduct = async () => {
      try {
        const response = await fetch(
          "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${cookies.token}`,
            },
            body: JSON.stringify({ product_id: "999" }),
          }
        );

        if (response.ok) {
          const { access: productAccess } = await response.json();
          setHasTypingAccess(productAccess === "access");
        } else {
          console.log("Product access check failed");
        }
      } catch (error) {
        console.error("Error checking product access:", error);
      }
    };

    checkAccessTypingProduct();
  }, [cookies.token]);

  const handleAccountClick = () => {
    if (!isLoggedIn) {
      window.location.href = "https://testportal.ssccglpinnacle.com/login";
    } else {
      toggleDropdown();
    }
  };

  return (
    <>
      <nav className={classes.nav2}>
        <ul className={classes.navlists}>
          <li>
            <Link className={classes.none_video} to="/">
              <FaHome className={classes.home} color="white" fontSize="1.5em" />
              &nbsp;Home
            </Link>
          </li>
          <li>
            <a
              href="https://testportal.ssccglpinnacle.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <AiOutlineLineChart
                className={classes.line}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Test Portal
            </a>
          </li>
          <li>
            <a
              href={hasTypingAccess ? "/typingexamselection" : "/typing"}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <FaKeyboard
                className={classes.upcoming}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Typing
            </a>
          </li>
          <li>
            <a
              href="/books"
              target="blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <HiOutlineBookOpen
                className={classes.book}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Books
            </a>
          </li>
          <li>
            <a
              href="https://videos.ssccglpinnacle.com/"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <AiOutlineLineChart
                className={classes.line}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Videos
            </a>
          </li>
          <li>
            <a
              href="/ebooks"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <BsFillMenuButtonWideFill
                className={classes.digital}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;E-Books
            </a>
          </li>
          <li>
            <a
              href="/blogs"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <ImBlogger
                className={classes.home}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Blogs
            </a>
          </li>

          {/* {isLoggedIn && (
    <li>
    <a
    href="/coins"
    target="_blank"
    rel="noopener noreferrer"
    className={classes.none_video}
  >
        <FaCoins
          className={classes.home}
          color="white"
          fontSize="1.5em"
        />
        &nbsp;Coins
      </a>
    </li>
  )} */}

          <li>
            <a
              href="/coins"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.none_video}
            >
              <FaCoins
                className={classes.home}
                color="white"
                fontSize="1.5em"
              />
              &nbsp;Coins
            </a>
          </li>

          <li onClick={handleAccountClick}>
            <AiOutlineUser
              className={classes.home}
              color="white"
              fontSize="1.5em"
            />
            &nbsp;{isLoggedIn ? "My Account" : "Login"}
          </li>
        </ul>
        {isLoggedIn && (
          <CSSTransition
            in={showDropdown}
            timeout={200}
            classNames={{
              enter: classes.dropdownEnter,
              enterActive: classes.dropdownEnterActive,
              exit: classes.dropdownExit,
              exitActive: classes.dropdownExitActive,
            }}
            unmountOnExit
          >
            <div className={classes.dropdownMenu}>
              <button onClick={logout} className={classes.dropdownItem}>
                Logout
              </button>
            </div>
          </CSSTransition>
        )}
      </nav>
    </>
  );
}

export default Navbar1;

// import React, { useEffect, useState } from "react";
// import classes from "./Navbar2.module.css";
// import { FaHome, FaUser, FaPencilAlt, FaKeyboard } from "react-icons/fa";
// import { BsFillMenuButtonWideFill } from "react-icons/bs";
// import { PiHandCoinsBold } from "react-icons/pi";
// import { HiOutlineBookOpen } from "react-icons/hi";
// import { AiOutlineLineChart } from "react-icons/ai";
// import {
//   MdOndemandVideo,
//   MdOutlineDirectionsRailwayFilled,
// } from "react-icons/md";
// import { ImBlogger } from "react-icons/im";
// import { AiOutlineUser } from "react-icons/ai";

// import { CSSTransition } from "react-transition-group";
// import { Link, useNavigate } from "react-router-dom";
// import { useAuth } from "../../Context/AuthContext";
// import { useCookies } from "react-cookie";

// function Navbar1(props) {
//   const { isLoggedIn, logout } = useAuth();
//   const [showDropdown, setShowDropdown] = useState(false);

//   const navigate = useNavigate();

//   const [cookies] = useCookies(["token"]);
//   const [hasTypingAccess, setHasTypingAccess] = useState(false);

//   const toggleDropdown = () => {
//     setShowDropdown((prevState) => !prevState);
//   };

//   useEffect(() => {
//     const checkAccessTypingProduct = async () => {
//       try {
//         const response = await fetch(
//           "https://auth.ssccglpinnacle.com/api/checkAccessTypingProduct",
//           {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Accept: "application/json",
//               Authorization: `Bearer ${cookies.token}`,
//             },
//             body: JSON.stringify({ product_id: "999" }),
//           }
//         );

//         if (response.ok) {
//           const { access: productAccess } = await response.json();
//           setHasTypingAccess(productAccess === "access");
//         } else {
//           console.log("Product access check failed");
//         }
//       } catch (error) {
//         console.error("Error checking product access:", error);
//       }
//     };

//     checkAccessTypingProduct();
//   }, [cookies.token]);

//   const handleAccountClick = () => {
//     if (!isLoggedIn) {
//       window.location.href = "https://testportal.ssccglpinnacle.com/login";
//     } else {
//       toggleDropdown();
//     }
//   };

//   return (
//     <>
//       <nav className={classes.nav2}>
//         <ul className={classes.navlists}>
//           <li>
//             <Link className={classes.none_video} to="/">
//               <FaHome className={classes.home} color="white" fontSize="1.5em" />
//               &nbsp;Home
//             </Link>
//           </li>
//           <li
//             onClick={() =>
//               (window.location.href = "https://testportal.ssccglpinnacle.com/")
//             }
//           >
//             <AiOutlineLineChart
//               className={classes.line}
//               color="white"
//               fontSize="1.5em"
//             />
//             &nbsp;Test Portal
//           </li>

//           <li>
//             <Link
//               className={classes.none_video}
//               to={hasTypingAccess ? "/typingexamselection" : "/typing"}
//             >
//               <FaKeyboard
//                 className={classes.upcoming}
//                 color="white"
//                 fontSize="1.5em"
//               />
//               &nbsp;Typing
//             </Link>
//           </li>
//           <li>
//             <Link className={classes.none_video} to="/books">
//               <HiOutlineBookOpen
//                 className={classes.book}
//                 color="white"
//                 fontSize="1.5em"
//               />
//               &nbsp;Books
//             </Link>
//           </li>
//           <li>
//             <Link className={classes.none_video} to="/videos">
//               <MdOndemandVideo
//                 className={classes.videoicon}
//                 color="white"
//                 fontSize="1.5em"
//               />
//               &nbsp;Videos{" "}
//             </Link>
//           </li>
//           <li>
//             <Link className={classes.none_video} to="/ebooks">
//               <BsFillMenuButtonWideFill
//                 className={classes.digital}
//                 color="white"
//                 fontSize="1.5em"
//               />
//               &nbsp;E-Books
//             </Link>
//           </li>

//           {/* <li>
//             <FaPencilAlt
//               className={classes.home}
//               color="white"
//               fontSize="1.5em"
//             />
//             &nbsp;SSC Kit
//           </li>
//           <li>
//             <MdOutlineDirectionsRailwayFilled
//               className={classes.home}
//               color="white"
//               fontSize="1.5em"
//             />
//             &nbsp;Railway Kit
//           </li>
//           <li>
//             <PiHandCoinsBold
//               className={classes.home}
//               color="white"
//               fontSize="1.5em"
//             />
//             &nbsp;Collect coins
//           </li> */}
//           <li>
//             <Link className={classes.none_video} to="/blog">
//               <ImBlogger
//                 className={classes.home}
//                 color="white"
//                 fontSize="1.5em"
//               />
//               &nbsp;Blogs
//             </Link>
//           </li>
//           <li onClick={handleAccountClick}>
//             <AiOutlineUser
//               className={classes.home}
//               color="white"
//               fontSize="1.5em"
//             />
//             &nbsp;{isLoggedIn ? "My Account" : "Login"}
//           </li>
//         </ul>
//         {isLoggedIn && (
//           <CSSTransition
//             in={showDropdown}
//             timeout={200}
//             classNames={{
//               enter: classes.dropdownEnter,
//               enterActive: classes.dropdownEnterActive,
//               exit: classes.dropdownExit,
//               exitActive: classes.dropdownExitActive,
//             }}
//             unmountOnExit
//           >
//             <div className={classes.dropdownMenu}>
//               {/* <Link to="/settings" className={classes.dropdownItem}>Profile</Link>
//               <Link to="/settings" className={classes.dropdownItem}>Settings</Link>
//               <Link to="/change-password" className={classes.dropdownItem}>Change Password</Link> */}
//               <button onClick={logout} className={classes.dropdownItem}>
//                 Logout
//               </button>
//             </div>
//           </CSSTransition>
//         )}
//       </nav>
//     </>
//   );
// }

// export default Navbar1;
