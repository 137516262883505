// import React, { useState, useEffect } from "react";
// import Swal from "sweetalert2";
// import styles from "./Cart.module.css";
// import { useNavigate } from "react-router-dom";
// import ApplyCoupon from "./ApplyCoupon";
// import Share from "./ShareComponent/Share";
// import { IoHeart } from "react-icons/io5";
// import { FaRegHeart } from "react-icons/fa6";
// import { useAuth } from "../../Context/AuthContext";
// import { useCookies } from "react-cookie";
// import LoginModal from "./LoginModal";

// const Cart = ({ courseId }) => {
//   const navigate = useNavigate();
//   const [showCoupon, setShowCoupon] = useState(false);
//   const [isShare, setIsShare] = useState(false);
//   const [courseTitle, setCourseTitle] = useState("");
//   const [courseDetails, setCourseDetails] = useState("");
//   const [price, setPrice] = useState(0);
//   const [hindiImage, setHindiImage] = useState("");
//   const [EnglishImage, setEnglishImage] = useState("");
//   const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);

//   const { isLoggedIn, user } = useAuth();
//   const emailId = user?.email_id || null;
//   const [cookies] = useCookies(["token", "email_id"]);
//   const [showModal, setShowModal] = useState(false);

//   useEffect(() => {
//     const fetchCourseDetails = async () => {
//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/course/${courseId}`
//         );
//         if (!response.ok) {
//           throw new Error(
//             `Failed to fetch course details. Status: ${response.status}`
//           );
//         }
//         const result = await response.json();
//         console.log(result);
//         setCourseTitle(result.courseTitle || "Course Title");
//         setCourseDetails(result.shortDescription || "No description available");
//         setPrice(result.price || 0);
//         setHindiImage(result.hindiCoverImage || "");
//         setEnglishImage(result.englishCoverImage || "");
//       } catch (error) {
//         console.error("Error fetching course details:", error);
//       }
//     };
//     console.log("courseID", courseId);
//     const fetchWishlistStatus = async () => {
//       if (!emailId) {
//         return;
//       }

//       try {
//         const response = await fetch(
//           `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}/${courseId}`
//         );

//         if (!response.ok) {
//           throw new Error(
//             `Failed to fetch wishlist. Status: ${response.status}`
//           );
//         }

//         const wishlistData = await response.json();
//         setIsAddedToWishlist(wishlistData.success);
//       } catch (error) {
//         console.error("Error checking wishlist status:", error);
//       }
//     };

//     fetchCourseDetails();

//     if (isLoggedIn) {
//       fetchWishlistStatus();
//     }
//   }, [courseId, emailId, isLoggedIn]);

//   const addToCartHandler = async () => {
//     if (!isLoggedIn || !emailId) {
//       setShowModal(true); // Show login modal if user is not logged in
//       return;
//     }

//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/usercart/${emailId}/${courseId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       const data = await response.json();

//       if (response.status === 201) {
//         Swal.fire({
//           title: "Success!",
//           text: "Course added to the cart successfully.",
//           icon: "success",
//           confirmButtonText: "OK",
//         });
//       } else if (
//         response.status === 409 &&
//         data.message === "Course is already in the cart."
//       ) {
//         Swal.fire({
//           title: "Info",
//           text: "Course is already in your cart.",
//           icon: "info",
//         });
//       } else {
//         Swal.fire({
//           title: "Failed",
//           text: data.message || "Failed to add course to cart.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       console.error("Error adding to cart:", error);
//       Swal.fire({
//         title: "Error",
//         text: "An unexpected error occurred. Please try again later.",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     }
//   };

//   const addToWishlistHandler = async () => {
//     if (!isLoggedIn || !emailId) {
//       setShowModal(true);
//       return;
//     }

//     try {
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/userwishlist/${emailId}/${courseId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${cookies.token}`,
//           },
//         }
//       );

//       const data = await response.json();
//       if (response.ok) {
//         setIsAddedToWishlist(true);
//         Swal.fire({
//           title: "Success!",
//           text: "Added to Wishlist",
//           icon: "success",
//           confirmButtonText: "OK",
//         });
//       } else {
//         Swal.fire({
//           title: "Failed",
//           text: data.message,
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       console.error("Error adding to wishlist:", error);
//     }
//   };

//   const buyBtnHandler = async () => {
//     if (!isLoggedIn) {
//       setShowModal(true); // Show login modal if the user is not logged in
//       return;
//     }

//     try {
//       // Step 1: Check if the course is already purchased
//       const purchasedResponse = await fetch(
//         `https://auth.ssccglpinnacle.com/api/user_purchased_videos_email/${emailId}`,
//         {
//           method: "GET",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       if (!purchasedResponse.ok) {
//         throw new Error("Failed to fetch purchased videos.");
//       }

//       const purchasedData = await purchasedResponse.json();
//       const isCoursePurchased =
//         purchasedData.purchasedVideos?.some(
//           (video) => video.productId === courseId
//         ) || false;

//       if (isCoursePurchased) {
//         Swal.fire({
//           title: "Course Already Purchased",
//           text: "You have already purchased this course. Redirecting to My Learning.",
//           icon: "info",
//           confirmButtonText: "Go to My Learning",
//         }).then(() => {
//           navigate(`/mylearning`);
//         });
//         return; // Stop further execution if course is already purchased
//       }

//       // Step 2: Add the course to the cart
//       const response = await fetch(
//         `https://auth.ssccglpinnacle.com/api/usercart/${emailId}/${courseId}`,
//         {
//           method: "POST",
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );

//       const data = await response.json();

//       // Step 3: Handle cart addition response
//       if (response.status === 201) {
//         Swal.fire({
//           title: "Success!",
//           text: "Course added to cart successfully. Redirecting to cart.",
//           icon: "success",
//           confirmButtonText: "Go to Cart",
//         }).then(() => {
//           navigate("/videos/cart");
//         });
//       } else if (
//         response.status === 409 &&
//         data.message === "Course is already in the cart."
//       ) {
//         Swal.fire({
//           title: "Info",
//           text: "This course is already in your cart. Redirecting to cart.",
//           icon: "info",
//           confirmButtonText: "Go to Cart",
//         }).then(() => {
//           navigate("/videos/cart");
//         });
//       } else {
//         Swal.fire({
//           title: "Failed",
//           text: data.message || "Failed to add course to cart.",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       console.error("Error adding course to cart:", error);
//       Swal.fire({
//         title: "Error",
//         text: "An unexpected error occurred. Please try again later.",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     }
//   };

//   return (
//     <>
//       <div className={styles["above-cart-fullpage"]}>
//         <div className={styles["cart-fullpage"]}>
//           <div className={styles["image-section"]}>
//             <img
//               src={hindiImage}
//               alt="Course in Hindi"
//               className={styles["image1"]}
//               id="cart-image1"
//             />
//             <img
//               src={EnglishImage}
//               alt="Course in English"
//               className={styles["image2"]}
//               id="cart-image2"
//             />
//           </div>
//           <div className={styles.overlay}>
//             <h2 className={styles.heading}>{courseTitle}</h2>
//             <div className={styles["Months-price-section"]}>
//               <span className={styles.months}>Duration: 12 months</span>
//               <p className={styles.price}>Price: ₹{price}</p>
//             </div>
//             <button className={styles["buyBtn"]} onClick={buyBtnHandler}>
//               Buy This Course
//             </button>
//             <div className={styles["cart-wishlist-Btn-div"]}>
//               <button className={styles["cartBtn"]} onClick={addToCartHandler}>
//                 Add To Cart
//               </button>
//               <button
//                 className={styles["wishListBtn"]}
//                 onClick={addToWishlistHandler}
//               >
//                 {isAddedToWishlist ? (
//                   <IoHeart size={20} color="red" />
//                 ) : (
//                   <FaRegHeart size={20} />
//                 )}
//               </button>
//             </div>
//           </div>
//           {isShare && <Share />}
//         </div>
//         <LoginModal show={showModal} handleClose={() => setShowModal(false)} />
//       </div>
//     </>
//   );
// };

// export default Cart;
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import styles from "./Cart.module.css";
import { useNavigate } from "react-router-dom";
import ApplyCoupon from "./ApplyCoupon";
import { IoHeart } from "react-icons/io5";
import { FaRegHeart } from "react-icons/fa6";
import { useAuth } from "../../Context/AuthContext";
import { useCookies } from "react-cookie";
import LoginModal from "./LoginModal";

const Cart = ({ courseId }) => {
  // console.log("courseId received from coursedetail", courseId);
  const navigate = useNavigate();
  const [showCoupon, setShowCoupon] = useState(false);
  const [isShare, setIsShare] = useState(false);
  const [courseTitle, setCourseTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [hindiImage, setHindiImage] = useState("");
  const [englishImage, setEnglishImage] = useState("");
  const [isAddedToWishlist, setIsAddedToWishlist] = useState(false);
  const [loading, setLoading] = useState(true);
  const { isLoggedIn, user } = useAuth();
  const emailId = user?.email_id || null;
  const [cookies] = useCookies(["token", "email_id"]);
  const [showModal, setShowModal] = useState(false);
  const [isCoursePurchased, setIsCoursePurchased] = useState(false);
  const [isCourseInCart, setIsCourseInCart] = useState(false);

  useEffect(() => {
    if (!courseId || !emailId) {
      return;
    }

    const fetchCourseDetails = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `https://auth.ssccglpinnacle.com/course/${courseId}`
        );
        if (!response.ok) {
          throw new Error(
            `Failed to fetch course details. Status: ${response.status}`
          );
        }
        const courseDetails = await response.json();
        setCourseTitle(courseDetails.courseTitle || "Course Title");
        setPrice(courseDetails.price || 0);
        setHindiImage(courseDetails.hindiCoverImage || "");
        setEnglishImage(courseDetails.englishCoverImage || "");
      } catch (error) {
        console.error("Error fetching course details:", error);
      } finally {
        setLoading(false);
      }
    };

    const checkCourseStatus = async () => {
      try {
        // Check if the course is purchased
        const purchasedResponse = await fetch(
          `https://auth.ssccglpinnacle.com/api/user_purchased_videos_email/${emailId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (purchasedResponse.ok) {
          const purchasedData = await purchasedResponse.json();
          const purchased = purchasedData.purchasedVideos?.some(
            (video) => video.productId === courseId
          );
          console.log("Is Course Purchased:", purchased); // Debugging purchase status
          setIsCoursePurchased(purchased || false);
        }

        // Check if the course is in the cart
        const cartResponse = await fetch(
          `https://auth.ssccglpinnacle.com/api/usercart/${emailId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (cartResponse.ok) {
          const cartData = await cartResponse.json();
          console.log("Cart Data:", cartData); // Debugging cart data
          const inCart = cartData.cartItems?.some(
            (item) => item.courseId === courseId
          );
          console.log("Is Course In Cart:", inCart); // Debugging cart status
          setIsCourseInCart(inCart || true);
        }
      } catch (error) {
        console.error("Error checking course status:", error);
      }
    };

    fetchCourseDetails();
    checkCourseStatus();
  }, [courseId, emailId]);

  if (loading) {
    return <div>Loading course details...</div>;
  }

  const addToCartHandler = async () => {
    if (!isLoggedIn || !emailId) {
      setShowModal(true);
      return;
    }

    if (isCoursePurchased) {
      Swal.fire({
        title: "Course Already Purchased",
        text: "You have already purchased this course. Redirecting to My Learning.",
        icon: "info",
        confirmButtonText: "Go to My Learning",
      }).then(() => {
        navigate(`/mylearning`);
      });
      return;
    }

    if (isCourseInCart) {
      Swal.fire({
        title: "Already in Cart",
        text: "This course is already in your cart.",
        icon: "info",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://auth.ssccglpinnacle.com/api/usercart/${emailId}/${courseId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.ok) {
        setIsCourseInCart(true);
        Swal.fire({
          title: "Success!",
          text: "Course added to the cart successfully.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else if (response.status === 409) {
        setIsCourseInCart(true); // Ensure state reflects the correct status
        Swal.fire({
          title: "Already in Cart",
          text: "This course is already in your cart.",
          icon: "info",
        });
      } else {
        const data = await response.json();
        Swal.fire({
          title: "Failed",
          text: data.message || "Failed to add course to cart.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error adding to cart:", error);
      Swal.fire({
        title: "Error",
        text: "An unexpected error occurred. Please try again later.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className={styles["above-cart-fullpage"]}>
        <div className={styles["cart-fullpage"]}>
          <div className={styles["image-section"]}>
            <img
              src={hindiImage}
              alt="Course in Hindi"
              className={styles["image1"]}
              id="cart-image1"
            />
            <img
              src={englishImage}
              alt="Course in English"
              className={styles["image2"]}
              id="cart-image2"
            />
          </div>
          <div className={styles.overlay}>
            <h2 className={styles.heading}>{courseTitle}</h2>
            <div className={styles["Months-price-section"]}>
              <span className={styles.months}>Duration: 12 months</span>
              <p className={styles.price}>Price: ₹{price}</p>
            </div>
            <button
              className={styles["buyBtn"]}
              onClick={() => navigate("/cart")}
            >
              Buy This Course
            </button>
            <div className={styles["cart-wishlist-Btn-div"]}>
              <button
                className={styles["cartBtn"]}
                onClick={addToCartHandler}
                disabled={isCoursePurchased || isCourseInCart}
              >
                {isCoursePurchased
                  ? "Course Purchased"
                  : isCourseInCart
                  ? "Already in Cart"
                  : "Add To Cart"}
              </button>

              <button className={styles["wishListBtn"]} onClick={() => {}}>
                {isAddedToWishlist ? (
                  <IoHeart size={20} color="red" />
                ) : (
                  <FaRegHeart size={20} />
                )}
              </button>
            </div>
          </div>
        </div>
        <LoginModal show={showModal} handleClose={() => setShowModal(false)} />
      </div>
    </>
  );
};

export default Cart;
